<template>
    <v-app>
        <v-dialog
                v-model="dialog" max-width="900">
            <v-card>
                <v-card-title>
                    {{`${edit?'Update':'Add'}`}} Assignment
                </v-card-title>
                <v-card-text>
                    <div class="row">
                        <div class="col-12">
                            <v-text-field outlined dense v-model="assignment.title"
                                          placeholder=" Title"></v-text-field>
                            <span class="text-danger"
                                  v-if="$v.assignment.title.$error"> Title is required</span>
                        </div>
                        <div class="col-12">
                            <ckeditor v-model="assignment.description"></ckeditor>
                            <span class="text-danger"
                                  v-if="$v.assignment.description.$error">Description is required</span>
                        </div>
                        <div class="col-4">
                            <v-select
                                    :items="programs"
                                    v-model="assignment.program_id"
                                    outlined
                                    dense
                                    label="Program"
                                    item-text="title"
                                    item-value="id"
                                    @change="getGrades()"
                            >

                            </v-select>

                        </div>
                        <div class="col-2">
                            <v-select
                                    :items="gradesLevels"
                                    v-model="assignment.grade_id"
                                    outlined
                                    dense
                                    label="Grade"
                                    item-text="title"
                                    item-value="id"
                                    @change="getCourses()"
                            >
                            </v-select>
                        </div>
                        <div class="col-6">
                            <v-select
                                    :items="courses"
                                    v-model="assignment.course_id"
                                    outlined
                                    dense
                                    label="Course "
                                    item-text="title"
                                    item-value="id"
                            >
                            </v-select>
                            <span class="text-danger"
                                  v-if="$v.assignment.course_id.$error">Course is required</span>
                        </div>
                        <div class="col-12">
                            <v-select
                                    :items="types"
                                    v-model="assignment.type"
                                    outlined
                                    dense
                                    label="Course "
                                    item-text="title"
                                    item-value="value"
                            >
                            </v-select>
                            <span class="text-danger"
                                  v-if="$v.assignment.type.$error">Type is required</span>
                        </div>

                        <div class="col-6">
                            <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="assignment.added_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="assignment.added_date"
                                            label="Added Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        v-model="assignment.added_date"
                                        no-title
                                        scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            text
                                            color="primary"
                                            @click="menu = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu.save(assignment.added_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                        </div>
                        <div class="col-6">
                            <v-menu
                                    ref="menu2"
                                    v-model="menu2"
                                    :close-on-content-click="false"
                                    :return-value.sync="assignment.due_date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                            v-model="assignment.due_date"
                                            label="Due Date"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            outlined
                                            dense
                                            v-bind="attrs"
                                            v-on="on"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                        v-model="assignment.due_date"
                                        no-title
                                        scrollable
                                >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                            text
                                            color="primary"
                                            @click="menu2 = false"
                                    >
                                        Cancel
                                    </v-btn>
                                    <v-btn
                                            text
                                            color="primary"
                                            @click="$refs.menu2.save(assignment.due_date)"
                                    >
                                        OK
                                    </v-btn>
                                </v-date-picker>
                            </v-menu>
                            <!--          <span class="text-danger" v-if="$v.exam.program_id.$error">End Date is required</span>-->
                        </div>

                        <div class="col-3">
                            <v-switch
                                    v-model="assignment.is_active"
                                    label="Status"
                            >
                            </v-switch>
                        </div>

                        <div class="col-12 text-right mt-4">
                            <v-btn
                                    class="btn btn-standard"
                                    depressed
                                    @click="resetForm"
                            >Cancel
                            </v-btn>
                            <v-btn
                                    class="text-white ml-2 btn btn-primary"
                                    depressed
                                    @click="createAndUpdate"
                                    :loading="isBusy"
                            >Save
                            </v-btn>
                        </div>
                    </div>
                </v-card-text>
            </v-card>

        </v-dialog>
    </v-app>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import AssignmentService from "@/core/services/assignement/AssignmentService";
    import GradeLevelService from "@/core/services/gradeLevel/GradeLevelService";
    import CoursesService from "@/core/services/courses/CoursesService";
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";

    const gradeLevelService = new GradeLevelService();
    const courseService = new CoursesService();
    const assignmentService = new AssignmentService();

    export default {
        name: "assignment-create-and-update",
        mixins: [AssignmentMixin],
        validations: {
            assignment: {
                title: {required},
                description: {required},
                course_id: {required},
                type: {required},
            }
        },
        data() {
            return {
                dialog: false,
                menu: false,
                menu2: false,
                isBusy: false,
                edit: false,
                types: [
                    {
                        title: 'File Submission',
                        value: 'file_submission'
                    },
                    {
                        title: 'Questions',
                        value: 'questions'
                    },
                    {
                        title: 'Quiz',
                        value: 'quiz'
                    }
                ]

            };
        }, mounted() {
            this.getPrograms()
        },
        computed: {},
        watch: {},
        methods: {
            formatDatePickerWise(date) {
                if (!date) return null
                const [day, month, year] = date.split('/')
                return `${year}-${month}-${day}`
            },
            getGrades() {
                gradeLevelService.getByProgram(this.assignment.program_id).then(response => {
                    this.gradesLevels = response.data;
                });
            },

            getCourses() {
                let query = {
                    program_id: this.assignment.program_id,
                    grade_id: this.assignment.grade_id,
                };
                courseService.paginate(query).then(response => {
                    this.courses = response.data.data;
                });
            },
            showModal(assignment = {}) {
                if (assignment.id) {
                    this.assignment = assignment;
                    this.assignment.start_date = this.formatDatePickerWise(this.assignment.start_date);
                    this.assignment.end_date = this.formatDatePickerWise(this.assignment.end_date);
                    this.edit = true
                    this.getPrograms();

                } else {
                    this.edit = false;
                    this.resetForm();
                }
                this.dialog = true;
            },
            hideModal() {
                this.dialog = false;
            },
            toggleModal() {
                // We pass the ID of the button that we want to return focus to
                // when the modal has hidden
                this.dialog = false;
            },
            createAndUpdate() {
                this.$v.$touch();
                if (this.$v.assignment.$error) {
                    setTimeout(() => {
                        this.$v.$reset();
                    }, 3000);
                } else {
                    if (this.edit) {
                        this.__update();
                    } else {
                        this.__create();
                    }
                }
            },
            __create() {
                this.isBusy = true;
                assignmentService.store(this.assignment).then(response => {
                    this.isBusy = false;
                    this.resetForm()
                    this.$snotify.success("Information added");
                });
            },
            __update() {
                this.isBusy = true;
                assignmentService.update(this.assignment.id, this.assignment).then(response => {
                    this.isBusy = false;
                    this.resetForm()
                    this.$snotify.success("Information updated");

                });
            }, resetForm() {
                this.assignment = {
                    level_id: null,
                    program_id: null,
                    title: null,
                    start_date: null,
                    end_date: null,
                    is_active: true
                }
                this.edit = false
                this.$v.$reset()
                this.hideModal()
                this.$emit("refresh");
            }
        },
    };
</script>
<style>
    .v-application--wrap {
        min-height: 0 !important;
    }
</style>
