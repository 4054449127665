<template>
    <v-app>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="card-title pt-3 px-3 d-flex justify-content-between">
                <div class="breadcrumb-left">
                  <h4> Assignment manager</h4>
                  <div class="breadcrumb-sub-header">
                    <router-link to="/dashboard">Dashboard</router-link>
                    \   Assignment manager
                  </div>
                </div>
<!--                <div class="breadcrumb-right" v-if="checkIsAccessible('academic-class','create')">
                  <div class="card-toolbar">
                    <v-btn  @click="openDialog()"  class="mt-4 btn btn-primary" style="color: #fff">
                      <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                      Add Assignment
                    </v-btn>
                  </div>
                </div>-->
              </div>
            </div>
            <div class="card-body">
              <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                <div class="row">
                  <v-col cols="12" md="3">
                    <v-select
                        outlined
                        :items="programs"
                        v-model="search.program_id"
                        @change="getGrades"
                        dense
                        item-text="title"
                        item-value="id"
                        label="Program"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="3" v-show="search.program_id">
                    <v-select
                        outlined
                        :items="gradesLevels"
                        v-model="search.grade_id"
                        dense
                        item-text="title"
                        item-value="id"
                        label="Semester/year"
                        @change="getCourses"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="3" v-show="search.program_id">
                    <v-select
                        outlined
                        :items="courses"
                        v-model="search.course_id"
                        dense
                        item-text="title"
                        item-value="id"
                        label="Course"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" :md="search.program_id ? 3 : 9" class="text-right">
                    <v-btn class="btn btn-primary text-white w-35" depressed :loading="isBusy" @click="__get">
                      <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                      Search
                    </v-btn>
                  </v-col>

                </div>
              </div>
              <div class="">
                <v-skeleton-loader
                    type="table-thead"
                    v-if="isBusy"
                >
                </v-skeleton-loader>

                <v-skeleton-loader
                    v-if="isBusy"
                    type="table-row-divider@25"
                >
                </v-skeleton-loader>
                <table class="table">
                  <thead>
                  <tr class="px-3">
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Due date</th>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-1">Action</th>
                  </tr>
                  </thead>
                  <tbody v-if="assignments.length > 0">
                    <tr v-for="(assignment, index) of assignments" :key="index">
                      <td class="px-3 wrap-column">
                        <router-link :to="{ name: 'assignment-summary', params: { assignment_id: assignment.id } }">
                          {{ assignment.title }}
                        </router-link>&nbsp;&nbsp;
                        <i class="fas fa-circle"
                          :class="assignment.is_active ? 'dot-active' : 'dot-inactive'"></i>
                      </td>

                      <td class="px-3 wrap-column">
                        <span v-if="assignment.due_date">
                        {{ assignment.due_date | moment("dddd, MMMM Do YYYY") || "N/A" }}
                        </span>
                      </td>
                      
                      <td class="px-3 wrap-column">
                        <span class="text-capitalize" v-if="assignment.submission_type">
                                {{ assignment.submission_type | format_status }}
                        </span>
                      </td>

                      <td class="px-1">
                        <div class="kt-widget__toolbar">
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown"
                              no-caret
                              right
                              no-flip
                              text="Actions"
                          >
                            <template v-slot:button-content>
                              <a href="#" class="" data-toggle="dropdown">
                                <!--              <span class="svg-icon svg-icon-success svg-icon-2x">-->
                                <!--begin::Svg Icon-->
                                <i class="flaticon-more-1"></i>
                                <!--end::Svg Icon-->
                                <!--              </span>-->
                              </a>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover">
                              <b-dropdown-text tag="div" class="navi-item">
                                <router-link :to="{
                                  name: 'assignment-edit', params: {
                                    assignment_id: assignment.id
                                  }
                                }" class="navi-link"
                                >
                                                              <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                              </span>
                                  <span class="navi-text">Edit</span>
                                </router-link>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <router-link ::to="{
                                  name: 'assignment-summary', params: {
                                    assignment_id: assignment.id
                                  }
                                }" class="navi-link"
                                >
                                                              <span class="navi-icon">
                                                                <i class="fa fa-file-archive"></i>
                                                              </span>
                                  <span class="navi-text">Submissions</span>
                                </router-link>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item"
                              >
                                <a href="#" class="navi-link"
                                  @click.prevent="deleteAcademicYear(assignment.id)">
                                                                      <span class="navi-icon">
                                                                        <i class="flaticon-delete"></i>
                                                                      </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                            <!--end::Navigation-->
                          </b-dropdown>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-if="assignments.length == 0">
                    <tr>
                      <td  class="px-3 text-center" colspan="4">No data available to display.</td>
                    </tr>
                  </tbody>
                </table>

              <b-pagination
                @input="__get"
                v-model="page" v-if="assignments.length > 0"
                :total-rows="total"
                :per-page="perPage" class="pull-right mt-7"
                first-number
                last-number
              ></b-pagination>
            </div>
          </div>
          <create-and-update ref="assignment" @refresh="__get"></create-and-update>
        </div>
        </div>
      </div>
    </v-app>
</template>
<script>
    import CreateAndUpdate from "./CreateAndUpdate";
    import AssignmentService from "@/core/services/assignement/AssignmentService";
    import {AssignmentMixin} from "@/mixins/AssignmentMixin";

    const assignmentService = new AssignmentService();

    export default {
        name: "assignment",
        mixins: [AssignmentMixin],
        components: {
            CreateAndUpdate
        },
        data() {
            return {
              assignments:[],
                closeOnClick: true,
              page: null,
              total: null,
              perPage: null,
              isBusy:false
            };
        },
        mounted() {
            this.getPrograms();
            this.__get();

        },
        methods: {
            __get() {
                this.getAssignments()
            },
            openDialog(assignment) {
                this.$refs["assignment"].showModal(assignment);
            },

            deleteAcademicYear(id) {
                this.$confirm({
                    message: `Are you sure?`,
                    button: {
                        no: "No",
                        yes: "Yes"
                    },
                    callback: confirm => {
                        if (confirm) {
                            assignmentService.delete(id).then(response => {
                                this.$snotify.success("Classroom deleted");
                                this.__get();
                            });
                        }
                    }
                });
            },
        }
    };
</script>
